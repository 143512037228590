/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-01-05 19:08:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
    orderList:[
        {
            title: "海信采购订单",
            path: "/order/cis",
            active:false,
        },
        {
            title: "渠道采购订单",
            path: "/order/dms",
            active:false,
        },
        {
            title: "海信采购进度",
            path: "/cispurchaseProgress",
            active:false,
        },
        {
            title: "渠道采购进度",
            path: "/dmspurchaseProgress",
            active:false,
        },
        {
            title: "认购参与结果",
            path: "/marketActivityResult",
            active:false,
        },
        {
            title: "常规订单扣款",
            path: "/",
            active:false,
        }
        ],
        invList:[
            {
                title: "我的库存",
                path: "/",
                active:false,
            },
            {
                title: "我的超期库存",
                path: "/",
                active:false,
            },
            {
                title: "分销商库存",
                path: "/",
                active:false,
            }
            ],
        statisticsList:[
            // {
            //     title: "XXXXXX",
            //     path: "/",
            //     active:false,
            // },
            // {
            //     title: "XXXXXX",
            //     path: "/",
            //     active:false,
            // },
            {
                title: "常规应急下单占比统计",
                path: "/statistics",
                active:false,
            }
            ],List:[
            {
                title: "我的库存",
                path: "/",
                active:false,
            },
            {
                title: "我的超期库存",
                path: "/",
                active:false,
            },
            {
                title: "分销商库存",
                path: "/",
                active:false,
            }
            ],
  };
  
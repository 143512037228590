import http from "@/utils/request";

export function getShopInfo(data) {
  return http({
    method: "get",
    url: "/custbase/getCustBaseInfo.nd?custId=" + data,
  });
}
export function getTableHeadInfo(data) {
  return http({
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
// 下载模板
export function downFile(params) {
  return http({
    url: '/cts/downFile.nd',
    method: 'get',
    params
  })
}
export function importFile(data) {
  return http({
    url: '/cts/importFile.nd',
    method: 'post',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  })
}

// 主子账号
export function getsonList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getMasterSonAccountList.nd",
    data: data
  });
}




import navUtil from "@/components/global/CustomizeNav/util";
import {
  downFile,
  getTableHeadInfo,
} from "./api";
import Util from "@/utils/utils";
import { publicPath } from "@/common/constant";
import axios from "axios";
export default {
  data() {
    return {
      isPayLoading:false,
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/engineering",
          name: "",
          title: "工程订单"
        },
        {
          path: "/batchImportPage",
          name: "",
          title: "订单批量导入"
        }
      ],
      chooseLoading:false,// 选择文件loading
      expLoading:false, // 确认导入loading
      downLoading:false, // 下载模板loading
      pageLoading:false, // 下载模板loading
      // 表头数据
      tcolumns: [],
      total:0,
      fileList:[],
      fileId: '',
      uploadUrl: publicPath + "/cts/importFile.nd",

    };
  },
  mounted() {
    this.getTableHead()
  },
  methods: {
    // 获取列表表头
    getTableHead(){
      let data  = {
        "serviceCode":'commonImport',
        "actionMethod":'loadColumnDefine',
        "businessType":this.$route.query.type
      }
      this.pageLoading = true
      getTableHeadInfo(data).then(res=>{
        let tableArr = []
        if(res.data.length > 0){
          res.data.forEach(item=>{
            tableArr.push({
              label: item.name,
              prop: item.field,
              key : item.field,
              width : 200
            })
          })
        }
        this.tcolumns = tableArr
        this.pageLoading = false
      })

    },
    //上传状态改变
    handleChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "done") {
        if (info.file.response.statusCodeValue == 200) {
          this.fileList = info.fileList;
          this.fileId = info.file.response.body.uuid_sn
          if( this.fileId ){
            let data  = {
              "serviceCode":'commonImport',
              "actionMethod":'loadImportedData',
              "businessType":this.$route.query.type,
              uuid_sn:this.fileId
            }
            getTableHeadInfo(data).then(res =>{
              this.total = res.data.body.length
              this.tableData = res.data.body
              this.getTableHead()
            })
          }
        } else {
          this.fileList = []
          this.$message.warning("上传失败，请联系管理员");
        }
      }
    },
    // 下载模板
    downLoad() {
      this.pageLoading = true
      let url = publicPath + `/cts/downFile.nd?actionMethod=downTemplate&businessType=${this.$route.query.type}&isCustomTemplate=1&cnFileName=${this.$route.query.excelName}&serviceCode=commonImport`
      window.open(url, "_blank");
      this.pageLoading = false
    },
    // 确认导入
    importFile(){
      // 数据校验不通过 给出提示
      let tips = this.tableData.length > 0 ? this.tableData.filter(it => it.validateType =='business_check_fail') :[]
      if(tips.length > 0) {
        this.$message.warning('数据检查不通过，不能导入')
        return
      }

      let data  = {
        "serviceCode":'commonImport',
        "actionMethod":'doImport',
        "businessType":this.$route.query.type,
        uuid_sn:this.fileId
      }
      this.pageLoading = true
      getTableHeadInfo(data).then(res =>{
        if( res.data.success == true){
          this.tableData = []
          this.total = 0
          this.fileList = []
          this.pageLoading = false
          this.$message.success('导入成功！')
        } else {
          this.pageLoading = false
          this.$message.warning(res.data.message)
        }
      })
    },


  }
};